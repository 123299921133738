var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addApplication"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"应用名称","rules":[
        { required: true, message: '请输入应用名称', trigger: 'blur' },
      ],"prop":"modelTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入应用名称","maxlength":8,"width":250},model:{value:(_vm.form.modelTitle),callback:function ($$v) {_vm.$set(_vm.form, "modelTitle", $$v)},expression:"form.modelTitle"}})],1),_c('el-form-item',{attrs:{"label":"应用图标","rules":[
        {
          required: true,
          message: '请上传应用图标',
          trigger: ['change', 'blur'],
        },
      ],"prop":"modelIconPic"}},[_c('v-upload',{attrs:{"limit":1,"myHeaders":_vm.myHeaders,"imgUrls":_vm.form.modelIconPic,"tips":"建议上传66px*66px"},on:{"update:myHeaders":function($event){_vm.myHeaders=$event},"update:my-headers":function($event){_vm.myHeaders=$event},"update:imgUrls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "modelIconPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"封面图","rules":[
        {
          required: false,
          message: '请上传封面图',
          trigger: ['change', 'blur'],
        },
      ],"prop":"coverPic"}},[_c('v-upload',{attrs:{"limit":1,"myHeaders":_vm.myHeaders,"imgUrls":_vm.form.coverPic,"tips":"建议上传500px*500px"},on:{"update:myHeaders":function($event){_vm.myHeaders=$event},"update:my-headers":function($event){_vm.myHeaders=$event},"update:imgUrls":function($event){return _vm.$set(_vm.form, "coverPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "coverPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"介绍图片","rules":[
        {
          required: true,
          message: '请上传介绍图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"descPic"}},[_c('v-upload',{attrs:{"limit":6,"myHeaders":_vm.myHeaders,"imgUrls":_vm.form.descPic,"tips":"建议上传150px*300px"},on:{"update:myHeaders":function($event){_vm.myHeaders=$event},"update:my-headers":function($event){_vm.myHeaders=$event},"update:imgUrls":function($event){return _vm.$set(_vm.form, "descPic", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "descPic", $event)}}})],1),_c('el-form-item',{attrs:{"label":"应用分类","rules":[
        { required: true, message: '请选择应用分类', trigger: 'blur' },
      ],"prop":"categoryId"}},[_c('v-select',{attrs:{"options":_vm.kindsList},model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}})],1),_c('el-form-item',{attrs:{"label":"适用社区","rules":[
        { required: false, message: '请选择适用社区', trigger: 'blur' },
      ],"prop":"targetTenantIdsList"}},[_c('v-select',{attrs:{"options":_vm.communityList,"multiple":true},on:{"change":_vm.changge},model:{value:(_vm.form.targetTenantIdsList),callback:function ($$v) {_vm.$set(_vm.form, "targetTenantIdsList", $$v)},expression:"form.targetTenantIdsList"}})],1),_c('el-form-item',{attrs:{"label":"应用简介","rules":[
        { required: true, message: '请输入应用简介', trigger: 'blur' },
      ],"prop":"modelDesc"}},[_c('v-input',{attrs:{"type":"textarea","placeholder":"请输入应用简介","width":350},model:{value:(_vm.form.modelDesc),callback:function ($$v) {_vm.$set(_vm.form, "modelDesc", $$v)},expression:"form.modelDesc"}})],1),_c('el-form-item',{attrs:{"label":"是否需要实名","rules":[{ required: true, message: '请选择', trigger: 'blur' }],"prop":"targetIsRealName"}},[_c('v-radio',{attrs:{"radioObj":_vm.isRealRadioObj},on:{"change":_vm.change},model:{value:(_vm.form.targetIsRealName),callback:function ($$v) {_vm.$set(_vm.form, "targetIsRealName", $$v)},expression:"form.targetIsRealName"}})],1),_c('el-form-item',{attrs:{"label":"应用类型","rules":[
        { required: true, message: '请选择应用类型', trigger: 'blur' },
      ],"prop":"targetType"}},[_c('v-select',{attrs:{"options":_vm.pagesType},on:{"change":_vm.changePagesType},model:{value:(_vm.form.targetType),callback:function ($$v) {_vm.$set(_vm.form, "targetType", $$v)},expression:"form.targetType"}})],1),(_vm.form.targetType == 1)?[_c('el-form-item',{attrs:{"label":"小程序页面路径","rules":[
          {
            required: true,
            message: '请输入小程序页面路径',
            trigger: 'blur',
          },
        ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入小程序页面路径","width":250},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/pages/index/index\")")])],1)]:_vm._e(),(_vm.form.targetType == 2)?[_c('el-form-item',{attrs:{"label":"h5应用路径","rules":[
          {
            required: true,
            message: '请输入h5应用路径',
            trigger: 'blur',
          },
        ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入h5应用路径","width":350},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/futureCreateWork/home\")")])],1)]:_vm._e(),(_vm.form.targetType == 3)?[_c('el-form-item',{attrs:{"label":"第三方h5应用路径","rules":[
          {
            required: true,
            message: '请输入第三方h5应用路径',
            trigger: 'blur',
          },
        ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方h5应用路径","width":350},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"https://dev.zhuneng.cn/zlb-h5-yljk/pages/entrance/thirdParty\")")])],1)]:_vm._e(),(_vm.form.targetType == 4)?[_c('el-form-item',{attrs:{"label":"第三方小程序appid","rules":[
          {
            required: true,
            message: '请输入第三方小程序appid',
            trigger: 'blur',
          },
        ],"prop":"targetAppid"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方小程序appid","width":250},model:{value:(_vm.form.targetAppid),callback:function ($$v) {_vm.$set(_vm.form, "targetAppid", $$v)},expression:"form.targetAppid"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"wx0cd4b682f7e7f72f\")")])],1),_c('el-form-item',{attrs:{"label":"小程序页面路径","rules":[
          {
            required: true,
            message: '请输入第三方小程序页面路径',
            trigger: 'blur',
          },
        ],"prop":"targetUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入第三方小程序页面路径","width":350},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"/pages/home/home?XKHospitalCode=XK33021201fGdxixsF\")")])],1)]:_vm._e(),_c('el-form-item',{attrs:{"label":"应用的管理后台页面","rules":[
        {
          required: false,
          message: '请输入应用的管理后台页面路径',
          trigger: 'blur',
        },
      ],"prop":"managerUrl"}},[_c('v-input',{attrs:{"placeholder":"请输入应用的管理后台页面路径","width":350},model:{value:(_vm.form.managerUrl),callback:function ($$v) {_vm.$set(_vm.form, "managerUrl", $$v)},expression:"form.managerUrl"}}),_c('span',{staticClass:"info"},[_vm._v("(例如：\"https://test.zhuneng.cn/portal/#/home/developer/myApplication\")")])],1),_c('el-form-item',{attrs:{"label":"额外参数","rules":[
        { required: false, message: '请输入额外参数', trigger: 'blur' },
      ]}},[_c('div',{staticClass:"extraData"},[_c('div',{attrs:{"id":"jsoneditor"}})])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }